import React, { useEffect, useState } from "react";
import { Spin, Row, Breadcrumb, Col, Button } from "antd";
import { withCookies, useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

// CSS
import './styles.css';

// ICONS
import { MdRemoveRedEye } from 'react-icons/md';

// API'S
import { POST_API } from "../../../services/Api";

// COMPONENTS
import TableComponent from "../../../components/TableComponent";
import DrawerComponent from "../../../components/DrawerComponent";

const Screen = () => {

    // VARIABLES
    const navigate = useNavigate();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    const [dataUser, setDataUser] = useState(null);
    const [dataLojas, setDataLojas] = useState([]);
    const [dataLojasTotal, setDataLojasTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [formloading, setFormLoading] = useState(false);
    const [formulario, setFormulario] = useState([]);
    const [defaultValues, setDefaultValues] = useState([]);

    const [visibleAdd, setVisibleAdd] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleView, setVisibleView] = useState(false);
    
    // COOKIES
    const [cookies] = useCookies(['TOKEN']);

    // COLUNAS
    const _columns = [
        { title: 'SQL', dataIndex: 'sql_log', key: 'sql_log' },
        { title: 'Responsável', dataIndex: 'name_credential', key: 'name_credential' },
        { title: 'Data/hora', dataIndex: 'format_datetime_insert', key: 'format_datetime_insert' },
        { title: 'Ações', key: 'action', width: 100, fixed: 'right', align: 'center', render: (text, _record) => (
            <Row gutter={[4,4]} className="table__actions">
                <Col><Button type="link" title="Visualizar" className="table__actions_button_view" onClick={() => {setVisibleView(true); setDefaultValues(_record)}}><MdRemoveRedEye size={15}/></Button></Col>
            </Row>
          ),
        },
    ]

    const loadDatas = async (search) => {
        setLoading(true)
        await POST_API('/logs/search.php', {token: cookies.TOKEN, search: search}).then(rs => rs.json()).then(res => {
            setDataLojas(res)
            setDataLojasTotal(res.length)
        }).finally(e => {
            setLoading(false)
        })
    }

    const closeAll = () => {
        setVisibleAdd(false)
        setVisibleEdit(false)
        setVisibleView(false)
    }

    const onSend = (values) => {
        loadDatas(values[0].value)
    }

    useEffect(() => {
        POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            if (res === 0) navigate('/dashboard')
            if (res.id_type_credential !== 'cqGERue0NkPXaiMkaPILuQ==') navigate('/dashboard')
            setDataUser(res)
        })
        setScreenLoad(true)
        setFormulario([
            { name: 'name_credential', label: 'Responável', type: 'text', span: 24, render: 'normal' },
            { name: 'ip_log', label: 'IP', type: 'text', span: 24, render: 'normal' },
            { name: 'datetime_insert', label: 'Data/hora', type: 'datetime-local', span: 24, render: 'normal' },
            { name: 'sql_log', label: 'SQL', type: 'text', span: 24, render: 'textarea' },
        ])
        loadDatas('')
    }, [screenLoad, cookies, navigate]);

    if (screenLoad && dataUser !== null) {
        return (
            <Row gutter={[16,16]} style={{paddingLeft: 10, paddingRight: 10}}>
                <Col span={12}>
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/dashboard">Dashboard</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Logs</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <TableComponent
                    _title='Lista de Logs'
                    _columns={_columns}
                    _data={dataLojas}
                    _total_data={dataLojasTotal}
                    _onSend={onSend}
                    _loading={loading}
                />
                <DrawerComponent
                    _title='Visualizar Log' 
                    _loading={formloading}
                    _formulario={formulario}
                    _defaultValues={defaultValues}
                    _visible={visibleView} _onVisible={closeAll}
                />
            </Row>
        )
    } else {
        return (<Row style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}><Spin /></Row>)
    }

}

export default withCookies(Screen);