import React from "react";
import { Route, Routes, BrowserRouter, Outlet } from "react-router-dom";

// SCREENS
import LoginScreen from "../pages/LoginScreen";
import ControlScreen from "../pages/ControlScreen";
import Page404Screen from "../pages/Page404Screen";
import LojasScreen from "../pages/Plataforma/LojasScreen";
import LojasConfigScreen from "../pages/Plataforma/LojasConfigScreen";
import UsuariosScreen from "../pages/Plataforma/UsuariosScreen";
import PlanosScreen from "../pages/Plataforma/PlanosScreen";
import Configuracoes from "../pages/Plataforma/ConfiguraoScreen";
import PerfilScreen from "../pages/PerfilScreen";
import LogsScreen from "../pages/Plataforma/LogsScreen";
import CategoriasScreen from "../pages/Plataforma/CategoriasScreen";
import ProdutosScreen from "../pages/Plataforma/ProdutosScreen";
import RegisterScreen from "../pages/RegisterScreen";
import Dashboards from "../pages/Dashboards";
import ProductsScreen from "../pages/Dashboards/ProductsScreen";
import ViewProductsScreen from "../pages/Dashboards/ViewProductsScreen";
import MyOrdersScreen from "../pages/Dashboards/OrdersScreen";
import OrdersScreen from "../pages/Plataforma/OrdersScreen";
import ForgotScreen from "../pages/ForgotScreen";

const AppStack = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/">
                    
                    {/* AUTENTICAÇÃO */}
                    <Route path="" element={<LoginScreen />} />
                    <Route path="cadastrar" element={<RegisterScreen />} />
                    <Route path="esquecisenha" element={<ForgotScreen />} />

                    {/* INICIO DO APP */}
                    <Route path="dashboard" element={<ControlScreen />}>

                        {/* AMBOS OS TIPOS DE LOGINS */}
                        <Route path="" element={<Dashboards />} />
                        <Route path="perfil" element={<PerfilScreen />} />

                        {/* APENAS SMARTMENU */}
                        <Route path="lojas" element={<Outlet />} >
                            <Route path="" element={<LojasScreen />} />
                            <Route path=":id/configuracao" element={<LojasConfigScreen />} />
                        </Route>
                        <Route path="planos" element={<PlanosScreen />} />
                        <Route path="logs" element={<LogsScreen />} />

                        {/* SMARTMENU E CLIENTES */}
                        <Route path="usuarios" element={<UsuariosScreen />} />
                        <Route path="configuracoes" element={<Configuracoes />} />

                        {/* APENAS CLIENTES */}
                        <Route path="pedidos" element={<OrdersScreen />} />
                        <Route path="categorias" element={<Outlet />} >
                            <Route path="" element={<CategoriasScreen />} />
                            {/* LISTA DE CATEGORIAS PARA USUARIOS */}
                            <Route path=":id" element={<ProductsScreen />} />
                        </Route>
                        <Route path="produtos" element={<Outlet />} >
                            <Route path="" element={<ProdutosScreen />} />
                            {/* LISTA DE PRODUTOS PARA USUARIOS */}
                            <Route path=":id" element={<ViewProductsScreen />} />
                        </Route>
                        
                        {/* APENAS USUARIOS */}
                        <Route path="meuspedidos" element={<MyOrdersScreen />} />
                        
                    </Route>
                    <Route path="*" element={<Page404Screen />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default AppStack;