import React, { useEffect, useState } from "react";
import { Spin, Row, Breadcrumb, Col, Tag, Button, Image } from "antd";
import { withCookies, useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

// CSS
import './styles.css';

// ICONS
import { MdEdit, MdRemoveRedEye, MdSettings } from 'react-icons/md';

// API'S
import { POST_API, SMART_ALERT } from "../../../services/Api";

// COMPONENTS
import TableComponent from "../../../components/TableComponent";
import DrawerComponent from "../../../components/DrawerComponent";


const Screen = () => {

    // VARIABLES
    const navigate = useNavigate();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    const [dataUser, setDataUser] = useState(null);
    const [dataLojas, setDataLojas] = useState([]);
    const [dataLojasTotal, setDataLojasTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [formloading, setFormLoading] = useState(false);
    const [formulario, setFormulario] = useState([]);
    const [defaultValues, setDefaultValues] = useState([]);

    const [visibleAdd, setVisibleAdd] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleView, setVisibleView] = useState(false);
    
    // COOKIES
    const [cookies] = useCookies(['TOKEN']);

    // COLUNAS
    const _columns = [
        { title: 'Cod.', dataIndex: 'id_product', key: 'id_product', width: 100, align: 'center' },
        { title: 'Foto', dataIndex: 'avatar_product', key: 'avatar_product', width: 100, render: (text, _record) => (
            <Image src={_record.avatar_product} />
        ) },
        { title: 'Nome', dataIndex: 'name_product', key: 'name_product' },
        { title: 'Preço', dataIndex: 'value_price_product', key: 'value_price_product', width: 120, align: 'center' },
        { title: 'Categoria', dataIndex: 'name_category', key: 'name_category', width: 200, align: 'center' },
        { title: 'Situação', dataIndex: '_status', key: '_status', align: 'center', width: 150, render: (text, _record) => (
            <Tag  key={text} color={_record?._status === 'deletado' ? 'volcano' : _record?._status === 'ativo' ? 'success' : 'warning'}>
                {_record?._status.toUpperCase()}
            </Tag>
        )},
        { title: 'Ações', key: 'action', width: 150, fixed: 'right', align: 'center', render: (text, _record) => (
            <Row gutter={[4,4]} className="table__actions">
                <Col><Button type="link" title="Visualizar" className="table__actions_button_view" onClick={() => {setVisibleView(true); setDefaultValues(_record)}}><MdRemoveRedEye size={15}/></Button></Col>
                <Col><Button type="link" title="Editar" className="table__actions_button_edit" onClick={() => {setVisibleEdit(true); setDefaultValues(_record)}}><MdEdit size={15}/></Button></Col>
            </Row>
          ),
        },
    ]

    const loadDatas = async (search) => {
        setLoading(true)
        await POST_API('/products/search.php', {token: cookies.TOKEN, search: search }).then(rs => rs.json()).then(res => {
            setDataLojas(res)
            setDataLojasTotal(res.length)
        }).finally(e => {
            setLoading(false)
        })
    }

    const closeAll = () => {
        setVisibleAdd(false)
        setVisibleEdit(false)
        setVisibleView(false)
    }

    const onSend = (values) => {
        loadDatas(values[0].value)
    }

    const onSendForm = async (values) => {
        setFormLoading(true)
        await POST_API('/products/save.php', {token: cookies.TOKEN, ...values}, values.avatar_product.fileList).then(rs => rs.json()).then(res => {
            SMART_ALERT(res.msg).then(e => {
                if (res.return) {
                    loadDatas('')
                    closeAll()
                }
            })
        }).finally(e => {
            setFormLoading(false)
        })
    }

    const onDelete = async () => {
        SMART_ALERT('Quer mesmo deletar esse item?', 'Atenção!', 'Sim', true, 'Não').then(e => {
            if (e.isConfirmed) {
                POST_API('/products/del.php', {token: cookies.TOKEN, ...defaultValues}).then(rs => rs.json()).then(res => {
                    SMART_ALERT(res.msg)
                    closeAll()
                    loadDatas('')
                })
            }
        })
    }

    const renderForm = async (user) => {
        var categorias = [];
        await POST_API('/categories/search.php', {token: cookies.TOKEN }).then(rs => rs.json()).then(res => {
            res.map(item => {
                console.log(item)
                categorias.push({label: item.name_category, value: item.id_category})
            })
        })
        var unidade = [];
        await POST_API('/unities/search.php', {token: cookies.TOKEN }).then(rs => rs.json()).then(res => {
            res.map(item => {
                unidade.push({label: item.name_unity, value: item.id_unity})
            })
        })
        if (user.id_type_credential === 'L+RrmCs/TTfi8KP6hqp0pg==') {
            setFormulario([
                { name: 'id_product', render: 'hidden' },
                { name: 'name_product', label: 'Produto', type: 'text', validate: [{ required: true, message: 'Campo obrigatório'}], span: 24, render: 'normal' },
                { name: 'price_product', label: 'Preço', type: 'number', validate: [{ required: true, message: 'Campo obrigatório'}], span: 24, render: 'normal' },
                { name: 'desc_product', label: 'Descrição', type: 'text', span: 24, render: 'textarea' },
                { name: 'avatar_product', label: 'Imagem', type: 'text', span: 24, render: 'upload' },
                { name: 'id_unity', label: 'Unidade', type: 'text', validate: [{ required: true, message: 'Campo obrigatório'}], span: 24, render: 'select', items: unidade},
                { name: 'id_category', label: 'Categoria', type: 'text', validate: [{ required: true, message: 'Campo obrigatório'}], span: 24, render: 'select', items: categorias},
            ])
        } else {
            setFormulario([
                { name: 'id_product', render: 'hidden' },
                { name: 'name_product', label: 'Produto', type: 'text', validate: [{ required: true, message: 'Campo obrigatório'}], span: 24, render: 'normal' },
                { name: 'price_product', label: 'Preço', type: 'number', validate: [{ required: true, message: 'Campo obrigatório'}], span: 24, render: 'normal' },
                { name: 'desc_product', label: 'Descrição', type: 'text', span: 24, render: 'textarea' },
                { name: 'avatar_product', label: 'Imagem', type: 'text', span: 24, render: 'upload' },
                { name: 'id_category', label: 'Categoria', type: 'text', validate: [{ required: true, message: 'Campo obrigatório'}], span: 24, render: 'select', items: categorias},
            ])
        }
    }

    useEffect(() => {
        POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            if (res === 0) navigate('/dashboard')
            if (res.id_type_credential !== 'L+RrmCs/TTfi8KP6hqp0pg==' && res.id_type_credential !== 'fG12miB0mgIv46xaa/qqlw==') navigate('/dashboard')
            setDataUser(res)
            renderForm(res)
        })
        setScreenLoad(true)
        loadDatas('')
    }, [screenLoad, cookies, navigate]);

    if (screenLoad && dataUser !== null) {
        return (
            <Row gutter={[16,16]} style={{paddingLeft: 10, paddingRight: 10}}>
                <Col span={12}>
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/dashboard">Dashboard</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Produtos</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col span={12} className="add_button_screen_col">
                    <Button type="primary" className="add_button_screen" onClick={() => setVisibleAdd(true)}>adicionar produto</Button>
                </Col>
                <TableComponent
                    _title='Lista de Produtos'
                    _columns={_columns}
                    _data={dataLojas}
                    _total_data={dataLojasTotal}
                    _onSend={onSend}
                    _loading={loading}
                />
                <DrawerComponent
                    _title='Adicionar Produto' 
                    _loading={formloading}
                    _formulario={formulario}
                    _send={onSendForm}
                    _defaultValues={{id_credential: 0, id_type_credential: '3'}}
                    _visible={visibleAdd} _onVisible={closeAll}
                />
                <DrawerComponent
                    _title='Visualizar Produto' 
                    _loading={formloading}
                    _formulario={formulario}
                    _defaultValues={defaultValues}
                    _delete={onDelete}
                    _visible={visibleView} _onVisible={closeAll}
                />
                <DrawerComponent
                    _title='Editar Produto' 
                    _loading={formloading}
                    _formulario={formulario}
                    _send={onSendForm}
                    _defaultValues={defaultValues}
                    _visible={visibleEdit} _onVisible={closeAll}
                />
            </Row>
        )
    } else {
        return (<Row style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}><Spin /></Row>)
    }

}

export default withCookies(Screen);