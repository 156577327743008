import React from "react";
import { Layout, Typography, Row, Button } from "antd";
import { withCookies } from "react-cookie";

// CSS
import './styles.css';

const Screen = () => {

    // VARIABLES
    const { Content } = Layout;
    const { Title } = Typography;

    return (
        <Layout>
            <Content className="layout_page404">
                <Row className="layout_page404_row">
                    <Title className="layout_page404_row_title">500</Title>
                    <Title className="layout_page404_row_subtitle">Opps! Sua aplicação está bloqueada</Title>
                    <Title className="layout_page404_row_text">Por favor, entre em contato com nosso suporte!</Title>
                </Row>    
            </Content>
        </Layout>
    )

}

export default withCookies(Screen);