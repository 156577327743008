import React, { useEffect, useState } from "react";
import { Avatar, Button, Checkbox, Col, Divider, Form, Image, Layout, Row, Select, Spin, Typography} from "antd";
import { withCookies, useCookies } from "react-cookie";
import { Outlet, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

// CSS
import './styles.css';
import music from './89780465.mp3';

// ICONS 
import { MdMenu, MdAlignHorizontalRight, MdOutlineShoppingCart, MdOutlineClose } from 'react-icons/md';

// API
import { POST_API, ACCOUNT_TAX, SMART_ALERT, number_format} from "../../services/Api";

// COMPONENT
import SidebarComponent from "../../components/SidebarComponent";

const Screen = () => {

    // VARIABLES
    const { Content, Sider, Header } = Layout;
    const { Paragraph } = Typography;
    const navigate = useNavigate();

    const som = new Audio(music);

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    const [dataUser, setDataUser] = useState(null);
    const [dataCart, setDataCart] = useState(null);
    const [dataAddress, setDataAddress] = useState(null);
    const [valorTotal, setValorTotal] = useState(0);
    const [formAddress, setFormAddress] = useState(null);
    const [formMethod, setFormMethod] = useState(null);
    const [dataCartProducts, setDataCartProducts] = useState([]);
    const [menuActive, setMenuActive] = useState(false);
    const [sideActive, setSideActive] = useState(false);
    const [sim, setSim] = useState(false);
    
    // COOKIES
    const [cookies] = useCookies(['LOGIN', 'TOKEN']);

    const deleteProduct = (id) => {
        POST_API('/carts/del_product.php', {token: cookies.TOKEN, id_cart_product: id}).then(rs => rs.json()).then(res => {
           loadCart()
        })
    }

    const loadCart =  async () => {
        await POST_API('/carts/verify.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            setDataCart(res)
            POST_API('/carts/list_product.php', {token: cookies.TOKEN, id_cart: res.id_cart}).then(rs => rs.json()).then(res => {
                setDataCartProducts(res)
                var total = 0
                res.map(async item => {
                    total += item.total_value_price_product
                })
                setValorTotal(total)
                
            })
            POST_API('/addresses/search.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
                setDataAddress(res)
            })
        })
    }

    const finalized = () => {
        if (formAddress === null) SMART_ALERT('Por favor, selecione o local de entrega!')
        if (formMethod === null) SMART_ALERT('Por favor, selecione a forma de pagamento!')
        if (dataCartProducts.length === 0) SMART_ALERT('Por favor, adicione um prorduto ao carrinho')
        if (formMethod !== null && formAddress !== null && dataCartProducts.length > 0) {
            SMART_ALERT('Finalizar pedido?', 'Tem certeza?', 'Sim', true, 'Não').then(rs => {
                if (rs.isConfirmed) {
                    POST_API('/carts/finalized.php', {token: cookies.TOKEN, id_cart: dataCart.id_cart, method_order: formMethod, id_address: formAddress}).then(rs => rs.json()).then(res => {
                        SMART_ALERT(res.msg)
                        if(res.return) loadCart()
                    })
                }
            })
        }
    }

    const verifyOrder = () => {
        POST_API('/orders/notify.php', {token: cookies.TOKEN, id_credential: ''}).then(rs => rs.json()).then(res => {
            if (res.length > 0) {
                SMART_ALERT('Novo pedido cadastrado!')
                var audio = new Audio(music);
                var playPromise = audio.play();
                // som.play();
            }
        })
    }

    useEffect(() => {
        POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            if (res === 0) navigate('/')
            setDataUser(res)
            if (res.id_type_credential === "/MkqpeTycaFmbALP/PE2/A==" || res.id_type_credential === "v8SLZzQX6XF8T8m04mHdYg==") {
                loadCart()
            }
            setInterval(() => {
                if (res.id_type_credential === "L+RrmCs/TTfi8KP6hqp0pg==" || res.id_type_credential === "v8SLZzQX6XF8T8m04mHdYg==") {
                    verifyOrder()
                }
            }, 5000);
        })
        setScreenLoad(true)
    }, [screenLoad, cookies, navigate]);

    if (screenLoad && dataUser !== null) {
        return (
            <Layout className="control_layout">
                <Sider className={menuActive ? "control_layout_sider active" : "control_layout_sider"}>
                    <SidebarComponent _user={dataUser} />
                </Sider>
                <Layout className="control_layout_layout">
                    <Content className={menuActive ? "control_layout_content active" : "control_layout_content"}>
                        <Header className="control_layout_header">
                            <Button className="control_layout_header_menu" type="link" onClick={() => setMenuActive(!menuActive)}><MdMenu size={20} /></Button>
                            <Button className="control_layout_header_menu" type="link" onClick={() => setSideActive(!sideActive)}>{dataUser.id_type_credential !== '/MkqpeTycaFmbALP/PE2/A==' ? <MdAlignHorizontalRight size={20} /> : <MdOutlineShoppingCart size={20}/>}</Button>
                        </Header>
                        <Row className="control_layout_outlet" ><Col span={24}>
                            <Outlet />
                        </Col></Row>
                    </Content>
                    <Sider className={sideActive ? "control_layout_sider_static active" : "control_layout_sider_static"}>
                        <Row className="sidebar_perfil">
                            <Avatar src={dataUser.avatar_credential} />
                            <Paragraph style={{width: 140, marginLeft: 10, marginBottom: 0}} ellipsis={true}>{dataUser.name_credential}</Paragraph>
                        </Row>
                        {dataUser.id_type_credential === "/MkqpeTycaFmbALP/PE2/A==" || dataUser.id_type_credential === "v8SLZzQX6XF8T8m04mHdYg==" ? (

                        <Row gutter={[16,16]}>
                            <Col span={24}>
                                <Typography style={{color: 'var(--primary)', fontSize: 18, fontWeight: '700', marginTop: 30}}>Seu pedido</Typography>
                            </Col>
                            {dataCartProducts.map((item, index) => ( 
                                <Col span={24} key={index}>
                                    <Row style={{alignItems: 'center'}}>
                                        <Button onClick={() => deleteProduct(item.id_cart_product)} style={{justifySelf: 'flex-end', marginRight: 10}} className="table__actions_button_delete" type="link"><MdOutlineClose /></Button>
                                        <Image style={{width: 50,height: 50, objectFit: 'cover', borderRadius: 8, marginRight: 8}} preview={false} src={item.avatar_product} />
                                        <Typography style={{width: 30}}>X {item.qtd_cart_product}</Typography>
                                        <div style={{marginRight: 8}}>
                                            <Typography style={{fontWeight: '700'}}>{item.name_product}</Typography>
                                            <Typography style={{fontSize: 16, color: 'var(--primary)'}}>{item.total_price_product}</Typography>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col span={24}>{item.obs_cart_product}</Col>
                                    </Row>
                                </Col>
                            ))}
                            <Col span={24}>
                            {formAddress === 0 ? (
                                <Row justify="space-between">
                                    <Typography style={{fontSize: 18}}>Total</Typography>
                                    <Typography style={{fontSize: 18, color: 'var(--primary)'}}>{number_format(valorTotal)}</Typography>
                                </Row>
                            ) : (
                                <>
                                <Row justify="space-between" style={{marginTop: 20}}>
                                    <Typography style={{fontSize: 18}}>Taxa de entrega:</Typography>
                                    <Typography style={{fontSize: 18, color: 'var(--primary)'}}>{number_format(ACCOUNT_TAX)}</Typography>
                                </Row>
                                <Divider/>
                                <Row justify="space-between">
                                    <Typography style={{fontSize: 18}}>Total</Typography>
                                    <Typography style={{fontSize: 18, color: 'var(--primary)'}}>{number_format(valorTotal+ACCOUNT_TAX)}</Typography>
                                </Row>
                                </>
                            )}
                                <Row style={{marginTop: 20}}>
                                    <Col span={24}>
                                        <Form layout="vertical">
                                            <Form.Item>
                                                <Checkbox onChange={e => {if (e.target.checked) {setFormAddress(0)} else {setFormAddress(null)}}}>Retirar no local</Checkbox>
                                            </Form.Item>
                                            {formAddress === 0 ? null : (
                                                <Form.Item  label="Selecione o endereço de entrega">
                                                    {dataAddress?.length > 0 ? (
                                                        <Select value={formAddress} onChange={e => setFormAddress(e)} placeholder="Meu endereço">
                                                            {dataAddress.map((item, index) => (
                                                                <Select.Option key={index} value={item.id_address}>{item.street_address}, {item.number_address} - {item.name_city}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    ) : (
                                                        <Link to="/dashboard/perfil"><Button block style={{borderColor: 'var(--primary)', color: 'var(--primary)', borderRadius: 8, height: 40}}>Cadastre um endereço</Button></Link>
                                                    )}
                                                </Form.Item>
                                            )}
                                            <Form.Item label="Selecione o modo de pagamento">
                                                <Select value={formMethod} onChange={e => setFormMethod(e)} placeholder="Modo de pagamento">
                                                    <Select.Option value="D">Dinheiro</Select.Option>
                                                    <Select.Option value="CC">Cartão de Crédito</Select.Option>
                                                    <Select.Option value="CD">Cartão de Débito</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                                <Button type="primary" onClick={finalized} block style={{backgroundColor: 'var(--primary)', border: 'none', marginTop: 20, borderRadius: 8, height: 40}}>Finalizar pedido</Button>
                            </Col>
                        </Row>
                        ):null}
                    </Sider>
                </Layout>
            </Layout>
        )
    } else {
        return (
            <Layout>
                <Content className="layout_login">
                    <Spin />         
                </Content>
            </Layout>
        )
    }

}

export default withCookies(Screen);