import React, { useEffect, useState } from "react";
import { Spin, Row, Breadcrumb, Col, Tabs } from "antd";
import { withCookies, useCookies } from "react-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";

// CSS
import './styles.css';

// API'S
import { POST_API } from "../../../services/Api";

// COMPONENTS
import UsuarioTab from './usuarios';
import UnidadesTab from "./unidades";
import ConfiguracoesTab from "./configuracoes";

const Screen = () => {

    // VARIABLES
    const navigate = useNavigate();
    const params = useParams();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    const [dataUser, setDataUser] = useState(null);
    const [dataLoja, setDataLoja] = useState(null);
    
    // COOKIES
    const [cookies] = useCookies(['TOKEN']);

    const loadDatas = async () => {
        await POST_API('/accounts/search.php', {token: cookies.TOKEN, id_account: params.id}).then(rs => rs.json()).then(res => {
            setDataLoja(res[0])
        })
    }

    useEffect(() => {
        POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            if (res === 0) navigate('/dashboard')
            if (res.id_type_credential !== 'cqGERue0NkPXaiMkaPILuQ==') navigate('/dashboard')
            setDataUser(res)
        })
        setScreenLoad(true)
        loadDatas()
    }, [screenLoad, cookies, navigate]);

    if (screenLoad && dataUser !== null && dataLoja !== null) {
        return (
            <Row gutter={[16,16]} style={{paddingLeft: 10, paddingRight: 10}}>
                <Col span={12}>
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/dashboard">Dashboard</Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/dashboard/lojas">Lojas</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>{dataLoja.name_account}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col span={24}>
                    <Tabs className="table__row_table">
                        <Tabs.TabPane tab="Configurações Padrão" key="1">
                            <ConfiguracoesTab account={dataLoja} user={dataUser} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Unidades" key="2">
                            <UnidadesTab account={dataLoja} user={dataUser} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Usuários" key="3">
                            <UsuarioTab account={dataLoja} user={dataUser} />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        )
    } else {
        return (<Row style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}><Spin /></Row>)
    }

}

export default withCookies(Screen);