import React, { useEffect, useState } from "react";
import { Spin, Row, Image, Card, Col, Drawer, Button, Typography, Divider } from "antd";
import { withCookies, useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

// CSS
import './styles.css';

// API'S
import { ACCOUNT_TAX, number_format, POST_API, SMART_ALERT } from "../../../services/Api";

const Screen = () => {

    // VARIABLES
    const navigate = useNavigate();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    const [dataUser, setDataUser] = useState(null);
    const [dataOrderView, setDataOrderView] = useState(null);
    const [visible, setVisible] = useState(false);
    const [valorTotal, setValorTotal] = useState(0);
    const [dataOrder, setDataOrder] = useState([]);
    const [dataCartProducts, setDataCartProducts] = useState([]);
    
    // COOKIES
    const [cookies] = useCookies(['TOKEN']);

    const loadCart = async (id) => {
        await POST_API('/carts/list_product.php', {token: cookies.TOKEN, id_cart: id}).then(rs => rs.json()).then(res => {
            setDataCartProducts(res)
            var total = 0
            res.map(async item => {
                total += item.total_value_price_product
            })
            setValorTotal(total)
        })
    }

    const cancel = () => {
        SMART_ALERT('Quer mesmo carcelar seu pedido?', 'Tem certeza?', 'Sim', true, 'Não').then(rs => {
            if (rs.isConfirmed) {
                POST_API('/orders/cancel.php', {token: cookies.TOKEN, id_order: dataOrderView.id_order}).then(rs => rs.json()).then(res => {
                    SMART_ALERT(res.msg)
                    if(res.return) {
                        POST_API('/orders/search.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
                            setDataOrder(res)
                        })
                    }
                })
            }
        })
    }

    useEffect(() => {
        POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            if (res === 0) navigate('/dashboard')
            setDataUser(res)
        })
        POST_API('/orders/search.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            setDataOrder(res)
        })
        setScreenLoad(true)
    }, [screenLoad, cookies, navigate]);

    if (screenLoad && dataUser !== null) {
        return (
            <Row gutter={[16,16]} style={{paddingLeft: 10, paddingRight: 10}}>
                {dataOrder && dataOrder.map((item, index) => {
                    return (
                        <Col span={24} index={index}>
                            <Card hoverable onClick={() => {loadCart(item.id_cart);setVisible(true);setDataOrderView(item)}} style={{ width: '100%', borderRadius: 15 }} >
                                <Card.Meta title={'#'+item.id_order} description={item.value_status_order} />
                            </Card>
                        </Col>
                    )
                })}
                <Drawer visible={visible} onClose={() => setVisible(false)}>
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Typography style={{color: 'var(--primary)', fontSize: 18, fontWeight: '700', marginTop: 30}}>Pedido</Typography>
                        </Col>
                        {dataCartProducts.map((item, index) => (
                            <Col span={24} key={index}>
                                <Row style={{alignItems: 'center'}}>
                                    <Image style={{width: 50,height: 50, objectFit: 'cover', borderRadius: 8, marginRight: 8}} preview={false} src={item.avatar_product} />
                                    <Typography style={{width: 30}}>X {item.qtd_cart_product}</Typography>
                                    <div style={{marginRight: 8}}>
                                        <Typography style={{fontWeight: '700'}}>{item.name_product}</Typography>
                                        <Typography style={{fontSize: 16, color: 'var(--primary)'}}>{item.total_price_product}</Typography>
                                    </div>
                                </Row>
                                <Row>
                                    <Col span={24}>{item.obs_cart_product}</Col>
                                </Row>
                            </Col>
                        ))}
                        <Col span={24}>
                            {dataOrderView?.id_address > 0 ? (
                                <>
                                <Row justify="space-between" style={{marginTop: 20}}>
                                    <Typography style={{fontSize: 18}}>Taxa de entrega:</Typography>
                                    <Typography style={{fontSize: 18, color: 'var(--primary)'}}>{number_format(ACCOUNT_TAX)}</Typography>
                                </Row>
                                <Divider/>
                                <Row justify="space-between">
                                    <Typography style={{fontSize: 18}}>Total</Typography>
                                    <Typography style={{fontSize: 18, color: 'var(--primary)'}}>{number_format(valorTotal+ACCOUNT_TAX)}</Typography>
                                </Row>
                                </>
                            ) : (
                                <Row justify="space-between">
                                    <Typography style={{fontSize: 18}}>Total</Typography>
                                    <Typography style={{fontSize: 18, color: 'var(--primary)'}}>{number_format(valorTotal)}</Typography>
                                </Row>
                            )}
                            {dataOrderView?.id_address > 0 ? (
                                <Row justify="space-between" style={{marginTop: 10}}>
                                    <Col span={24}><Typography style={{fontSize: 18}}>Endereço de entrega</Typography></Col>
                                    <Col span={24}><Typography style={{fontSize: 18, color: 'var(--primary)'}}>{dataOrderView?.street_address}, {dataOrderView?.number_address} - {dataOrderView?.district_address} - {dataOrderView?.name_city} / {dataOrderView?.initials_state} - {dataOrderView?.cep_address} </Typography></Col>
                                </Row>
                            ) : (
                                <Row justify="space-between" style={{marginTop: 10}}>
                                    <Col span={24}><Typography style={{fontSize: 18}}>Retirar no local</Typography></Col>
                                </Row>
                            )}
                            <Row justify="space-between" style={{marginTop: 10}}>
                                <Col span={24}><Typography style={{fontSize: 18}}>Forma de pagamento</Typography></Col>
                                <Col span={24}><Typography style={{fontSize: 18, color: 'var(--primary)'}}>{dataOrderView?.value_method_order}</Typography></Col>
                            </Row>
                            {dataOrderView?.status_order !== 'C' ? <Button type="primary" onClick={cancel} block style={{backgroundColor: 'var(--primary)', border: 'none', marginTop: 20, borderRadius: 8, height: 40}}>Cancelar pedido</Button> : null}
                        </Col>
                    </Row>
                </Drawer>
            </Row>
        )
    } else {
        return (<Row style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}><Spin /></Row>)
    }

}

export default withCookies(Screen);