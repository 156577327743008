import React, { useEffect, useState } from "react";
import { Spin, Row, Image, Card, Col, Typography, InputNumber, Button, Input } from "antd";
import { withCookies, useCookies } from "react-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";

// CSS
import './styles.css';

// ICONS 
import { MdOutlineAdd } from 'react-icons/md';
import { MdOutlineHorizontalRule } from 'react-icons/md';

// API'S
import { POST_API, SMART_ALERT } from "../../../services/Api";

const Screen = () => {

    // VARIABLES
    const navigate = useNavigate();
    const params = useParams();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    const [dataUser, setDataUser] = useState(null);
    const [dataCart, setDataCart] = useState(null);
    const [dataProdutos, setDataProdutos] = useState(null);
    const [qtd, setQtd] = useState(1);
    const [obs, setObs] = useState("");
    
    // COOKIES
    const [cookies] = useCookies(['TOKEN']);

    const sendForm = () => {
        POST_API('/carts/add_product.php', {token: cookies.TOKEN, qtd_cart_product: qtd, id_product: params.id, id_cart: dataCart.id_cart, obs_cart_product: obs}).then(rs => rs.json()).then(res => {
            if (!res.return) {
                SMART_ALERT(res.msg)
            } else {
                navigate('/dashboard')
            }
        })
    }

    useEffect(() => {
        POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            if (res === 0) navigate('/dashboard')
            setDataUser(res)
        })
        POST_API('/products/search.php', {token: cookies.TOKEN, id_product: params.id}).then(rs => rs.json()).then(res => {
            setDataProdutos(res[0])
        })
        POST_API('/carts/verify.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            setDataCart(res)
        })
        setScreenLoad(true)
    }, [screenLoad, cookies, navigate]);

    if (screenLoad && dataUser !== null && dataProdutos !== null) {
        return (
            <Row gutter={[16,16]}>
                <Col xs={24} md={{span: 6, offset: 6}} >
                    <Card
                        style={{ width: '100%', borderRadius: 8 }}
                        cover={<Image style={{ width: '100%', borderTopLeftRadius: 8, borderTopRightRadius: 8 }} src={dataProdutos.avatar_product} preview={false} />}
                    >
                        <Typography style={{fontWeight: '700', fontSize: 18}}>{dataProdutos.name_product}</Typography>
                        <Typography style={{opacity: '0.8', marginTop: 8, marginBottom: 8, textAlign: 'justify'}}>{dataProdutos.desc_product}</Typography>
                        <Typography style={{color: 'var(--primary)'}}>{dataProdutos.value_price_product}</Typography>
                    </Card>
                </Col>
                <Col xs={24} md={8} >
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Input.TextArea value={obs} onChange={(e) => setObs(e.target.value)} style={{borderRadius: 15}} rows={4} placeholder="Observações" />
                        </Col>
                        <Col xs={24}>
                            <Row>
                                <Col span={10} style={{fontSize: 20, backgroundColor: 'var(--secondary)', height: 42, padding: 5, borderRadius: 8, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Button disabled={qtd === 1} type="link" onClick={() => setQtd(qtd-1)}><MdOutlineHorizontalRule size={22}/></Button>
                                    {qtd}
                                    <Button type="link" onClick={() => setQtd(qtd+1)}><MdOutlineAdd size={22}/></Button>
                                </Col>
                                <Col span={13} offset={1}>
                                    <Button style={{height: 42, backgroundColor: 'var(--primary)', border:'none', borderRadius: 8}} block type="primary" onClick={sendForm}>Adicionar R$ {dataProdutos.price_product * qtd}</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            )
    } else {
        return (<Row style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}><Spin /></Row>)
    }

}

export default withCookies(Screen);