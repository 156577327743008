import React, { useEffect, useState } from "react";
import { Spin, Row, Breadcrumb, Col, Tag, Button, Typography, Image, Form, Input, Upload, Select, Avatar, Tabs } from "antd";
import { withCookies, useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import ImgCrop from 'antd-img-crop';

// CSS
import './styles.css';

// ICONS
import { MdEdit, MdRemoveRedEye, MdSettings } from 'react-icons/md';

// API'S
import { ACCOUNT_LOGO, POST_API, setLogo, SMART_ALERT } from "../../services/Api";

// COMPONENTS
import TableComponent from "../../components/TableComponent";
import DrawerComponent from "../../components/DrawerComponent";
import AddressesScreen from "../AddressesScreen";


const Screen = () => {

    // VARIABLES
    const navigate = useNavigate();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [dataUser, setDataUser] = useState(null);
    
    // COOKIES
    const [cookies] = useCookies(['TOKEN']);

    const [form] = Form.useForm()

    const onSend = (values) => {
        setLoadingForm(true)
        POST_API('/credentials/save_config.php', {token: cookies.TOKEN, ...values}, fileList).then(rs => rs.json()).then(res => {
            SMART_ALERT(res.msg)
            if (res.return) {
                POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
                    setDataUser(res)
                })
            }
        }).finally(() => {
            setLoadingForm(false)
        })
    }

    const onSendPass = (values) => {
        setLoadingForm(true)
        POST_API('/credentials/update_password.php', {token: cookies.TOKEN, ...values, ...dataUser}, fileList).then(rs => rs.json()).then(res => {
            SMART_ALERT(res.msg)
        }).finally(() => {
            setLoadingForm(false)
        })
    }

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    useEffect(() => {
        setScreenLoad(true)
        POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(async res => {
            if (res === 0) navigate('/dashboard')
            setDataUser(res)
        })
    }, [screenLoad, cookies, navigate]);

    if (screenLoad && dataUser !== null) {
        form.setFieldsValue(dataUser)
        return (
            <Row gutter={[16,16]}>
                <Col span={12}>
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/dashboard">Dashboard</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Perfil</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col span={24}>
                    <Row gutter={[4,4]} style={{paddingLeft: 10, paddingRight: 10}} className="table__row_table">
                        <Col span={24}>
                            <Tabs>
                                <Tabs.TabPane tab="Meu perfil" key="1">
                                    <Row gutter={[16,16]}>
                                        <Col md={8} xs={24} className="col_perfil">
                                            <Image style={{width: '100%'}} src={dataUser.avatar_credential} preview={false} />
                                        </Col>
                                        <Col md={8} xs={24}>
                                            <Form layout="vertical" onFinish={onSend} form={form} defaultValue={dataUser}>
                                                <Form.Item className="formitem_class" name="avatar_credential" label="Nova foto de perfil">
                                                    <ImgCrop rotate>
                                                        <Upload onChange={onChange} listType="picture-card" maxCount={1} accept="image/jpeg,image/png">
                                                            Selecionar
                                                        </Upload>
                                                    </ImgCrop>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button className="form_buttons primary" block type="primary" htmlType="submit" loading={loadingForm}>Salvar</Button>
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Endereços" key="2">
                                    <AddressesScreen type="C" />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Mudar senha" key="3">
                                    <Row gutter={[16,16]}>
                                        <Col xs={24} md={{span: 8, offset: 8}}>
                                            <Form layout="vertical" onFinish={onSendPass} form={form} defaultValue={dataUser}>
                                                <Form.Item className="formitem_class" name="password_old" label="Senha atual">
                                                    <Input type='password' />
                                                </Form.Item>
                                                <Form.Item className="formitem_class" name="password_new" label="Nova senha">
                                                    <Input type='password' />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button className="form_buttons primary" block type="primary" htmlType="submit" loading={loadingForm}>Salvar</Button>
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Tabs.TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    } else {
        return (<Row style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}><Spin /></Row>)
    }

}

export default withCookies(Screen);