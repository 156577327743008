import React, { useEffect, useState } from "react";
import { Spin, Row, Breadcrumb, Col, Button, Image, Form, Input, Upload, InputNumber } from "antd";
import { withCookies, useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

// CSS
import './styles.css';

// API'S
import { POST_API, setLogo, SMART_ALERT } from "../../../services/Api";

const Screen = () => {

    // VARIABLES
    const navigate = useNavigate();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [configAccount, setConfigAccount] = useState(null);
    const [dataUser, setDataUser] = useState(null);
    
    // COOKIES
    const [cookies] = useCookies(['TOKEN']);

    const [form] = Form.useForm()

    const onSend = (values) => {
        setLoadingForm(true)
        POST_API('/accounts/save_config.php', {token: cookies.TOKEN, ...values}, values.logo_empresa?.fileList).then(rs => rs.json()).then(res => {
            SMART_ALERT(res.msg)
            if (res.return) {
                POST_API('/accounts/config.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
                    setConfigAccount(res)
                })
            }
        }).finally(() => {
            setLoadingForm(false)
        })
    }

    useEffect(() => {

        POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(async res => {

            if (res === 0) navigate('/dashboard')
            if (res.id_type_credential !== 'cqGERue0NkPXaiMkaPILuQ==' && res.id_type_credential !== 'L+RrmCs/TTfi8KP6hqp0pg==') navigate('/dashboard')
            setDataUser(res)
            
            POST_API('/accounts/config.php', {token: cookies.TOKEN, id_account: res.id_account}).then(rs3 => rs3.json()).then(res3 => {
                setLogo(res3.logo_empresa)
                setConfigAccount(res3)
            })

        }).then(e => {
            setScreenLoad(true)
        })
        
    }, [screenLoad, cookies, navigate]);

    if (screenLoad && dataUser !== null && configAccount !== null) {
        form.setFieldsValue(configAccount)
        return (
            <Row gutter={[16,16]}>
                <Col span={12}>
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/dashboard">Dashboard</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Configuracoes</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col span={24}>
                    <Row gutter={[4,4]} style={{paddingLeft: 10, paddingRight: 10}} className="table__row_table">
                        <Col span={24}>
                            <Row gutter={[16,16]}>
                                <Col span={8}>
                                    <Image style={{width: '100%'}} src={configAccount.logo_empresa} preview={false} />
                                </Col>
                                <Col span={8}>
                                    <Form layout="vertical" onFinish={onSend} form={form}>
                                        <Form.Item className="formitem_class" name="logo_empresa" label="Nova logo">
                                            <Upload listType="picture-card" maxCount={1} accept="image/png">
                                                Selecionar
                                            </Upload>
                                        </Form.Item>
                                        <Form.Item className="formitem_class" name="cor_primaria" label="Cor Destaque">
                                            <Input type="color" onChange={(e) => document.querySelector(':root').style.setProperty('--primary', `${e.target.value}`)} />
                                        </Form.Item>
                                        <Form.Item className="formitem_class" name="taxa_empresa" label="Taxa de Entrega Padrão">
                                            <InputNumber min={0} />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button className="form_buttons primary" block type="primary" htmlType="submit" loading={loadingForm}>Salvar</Button>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    } else {
        return (<Row style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}><Spin /></Row>)
    }

}

export default withCookies(Screen);