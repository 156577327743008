import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { POST_API, ACCOUNT_ID, setLogo, setTax } from "./services/Api";
import Page500Screen from './pages/Page500Screen';

const root = ReactDOM.createRoot(document.getElementById('root'));
const favicon = document.getElementById("favicon");

POST_API('/accounts/render.php', {ACCOUNT_ID}).then(rs => rs.json()).then(res => {
  document.querySelector(':root').style.setProperty('--primary', `${res.cor_primaria}`);
  document.title = res.name_account
  favicon.setAttribute("href", res.logo_empresa); 
  setLogo(res.logo_empresa)
  if (res?.taxa_empresa) {
    setTax(Number(res.taxa_empresa))
  }
  return res
}).then(res => {
  console.log(res)
  if (res.status_account === 'A') {
    root.render(
        <App />
    );
  } else {
    root.render(
      <Page500Screen />
  );
  }
}).catch(e => {
  root.render(
    <Page500Screen />
);
})

reportWebVitals();
