import React, { useEffect, useState } from "react";
import { Spin, Row, Card, Col, Typography, Segmented, Statistic } from "antd";
import { withCookies, useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

// CSS
import './styles.css';

// ICONS 
import { MdOutlineDeliveryDining, MdOutlineCancel, MdOutlineCheck, MdOutlineAttachMoney } from 'react-icons/md'
import { RiseOutlined, FallOutlined, StockOutlined } from '@ant-design/icons';

// API'S
import { POST_API, SMART_ALERT } from "../../../services/Api";

const Screen = () => {

    // VARIABLES
    const navigate = useNavigate();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    const [dataUser, setDataUser] = useState(null);
    const [dataDash, setDataDash] = useState(null);
    
    // COOKIES
    const [cookies] = useCookies(['TOKEN']);

    const colorRise = '#3f8600';
    const colorFall = '#cf1322';
    const colorEqls = '#000000';

    const loadDash = async (periodo) => {
        await POST_API('/dashboard/admin.php', {token: cookies.TOKEN, periodo}).then(rs => rs.json()).then(res => {
            setDataDash(res)
        })
    }

    useEffect(() => {
        POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            if (res === 0) navigate('/dashboard')
            setDataUser(res)
        })
        loadDash('Hoje')
        setScreenLoad(true)
    }, [screenLoad, cookies, navigate]);

    if (screenLoad && dataUser !== null) {
        return (
            <Row gutter={[16,16]} style={{paddingLeft: 10, paddingRight: 10}}>
                <Col span={24}>
                <Segmented onChange={(e) => loadDash(e)} options={['Hoje', 'Últimos 7 dias', 'Esse mês', 'Esse ano']} />
                </Col>
                <Col span={24}>
                    <Row gutter={[16,16]}>
                        <Col xs={24} md={6}>
                            <Card hoverable className="card_KPI">
                                <div className="KPI"><MdOutlineDeliveryDining color="#FFF" size={40} /></div>
                                <Statistic className="KPI_dados"
                                    title="Pedidos em espera"
                                    value={dataDash?.p_espera}
                                    valueStyle={(dataDash?.p_espera > dataDash?.p_espera_comparacao) ? { color: colorRise } : (dataDash?.p_espera < dataDash?.p_espera_comparacao) ? { color: colorFall } : { color: colorEqls }}
                                    suffix={(dataDash?.p_espera > dataDash?.p_espera_comparacao) ? <RiseOutlined /> : (dataDash?.p_espera < dataDash?.p_espera_comparacao) ? <FallOutlined /> : <StockOutlined />}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} md={6}>
                            <Card hoverable className="card_KPI">
                                <div className="KPI"><MdOutlineCheck color="#FFF" size={40} /></div>
                                <Statistic className="KPI_dados"
                                    title="Pedidos concluídos"
                                    value={dataDash?.p_entregue}
                                    valueStyle={(dataDash?.p_entregue > dataDash?.p_entregue_comparacao) ? { color: colorRise } : (dataDash?.p_entregue < dataDash?.p_entregue_comparacao) ? { color: colorFall } : { color: colorEqls }}
                                    suffix={(dataDash?.p_entregue > dataDash?.p_entregue_comparacao) ? <RiseOutlined /> : (dataDash?.p_entregue < dataDash?.p_entregue_comparacao) ? <FallOutlined /> : <StockOutlined />}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} md={6}>
                            <Card hoverable className="card_KPI">
                                <div className="KPI"><MdOutlineCancel color="#FFF" size={40} /></div>
                                <Statistic className="KPI_dados"
                                    title="Pedidos cancelados"
                                    value={dataDash?.p_cancelado}
                                    valueStyle={(dataDash?.p_cancelado < dataDash?.p_cancelado_comparacao) ? { color: colorRise } : (dataDash?.p_cancelado > dataDash?.p_cancelado_comparacao) ? { color: colorFall } : { color: colorEqls }}
                                    suffix={(dataDash?.p_cancelado < dataDash?.p_cancelado_comparacao) ? <RiseOutlined /> : (dataDash?.p_cancelado > dataDash?.p_cancelado_comparacao) ? <FallOutlined /> : <StockOutlined />}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} md={6}>
                            <Card hoverable className="card_KPI">
                                <div className="KPI"><MdOutlineAttachMoney color="#FFF" size={40} /></div>
                                <Statistic className="KPI_dados"
                                    title="Renda total"
                                    value={`R$ ${dataDash?.renda}`}
                                    valueStyle={(dataDash?.renda > dataDash?.renda_comparacao) ? { color: colorRise } : (dataDash?.renda < dataDash?.renda_comparacao) ? { color: colorFall } : { color: colorEqls }}
                                    suffix={(dataDash?.renda > dataDash?.renda_comparacao) ? <RiseOutlined /> : (dataDash?.renda < dataDash?.renda_comparacao) ? <FallOutlined /> : <StockOutlined />}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    } else {
        return (<Row style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}><Spin /></Row>)
    }

}

export default withCookies(Screen);