import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Image, Form, Input, Button, Spin, Typography} from "antd";
import { withCookies, useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

// CSS
import './styles.css';

// API'S
import { POST_API, SMART_ALERT, ACCOUNT_LOGO, ACCOUNT_ID } from "../../services/Api";
import ReactInputMask from "react-input-mask";

const Screen = () => {

    // VARIABLES
    const { Content } = Layout;
    const navigate = useNavigate();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    
    // COOKIES
    const [cookies] = useCookies(['TOKEN']);

    // FORMS
    const [login_form] = Form.useForm();

    useEffect(() => {
        if (cookies.TOKEN !== '') {
            POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
                if (res.id_credential) {
                    navigate('dashboard')
                }
            })
        }
        setScreenLoad(true)
    }, [screenLoad, cookies, navigate]);

    const onSend = async (values) => {
        await POST_API('/credentials/save.php', {...values, id_account: ACCOUNT_ID, id_type_credential: 7, token: '-'}).then(rs => rs.json()).then(res => {
            SMART_ALERT(res.msg)
            if (res.return) {
                navigate('/')
            }
        })
    }

    return (
        <Layout>
            <Content className="layout_register">
                {screenLoad ? (
                    <Row gutter={[25,25]} className="layout_register_row">
                        <Col md={12} xs={24} className="layout_register_row_col">
                            <Image src={ACCOUNT_LOGO} width={'100%'} preview={false} />
                        </Col>
                        <Col md={12} xs={24}>
                            <Form name="register_form" onFinish={onSend} form={login_form} >
                                <Typography className="register_form_title">Cadastre-se</Typography>
                                <Form.Item name="name_credential" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                                    <Input placeholder="Nome" className="register_form_input" />
                                </Form.Item>
                                <Form.Item name="phone_credential" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                                    <ReactInputMask mask='(99) 99999-9999'>
                                        {(inputprops) => <Input {...inputprops} placeholder="Telefone" className="register_form_input" />}
                                    </ReactInputMask>
                                </Form.Item>
                                <Form.Item name="email_credential" rules={[{ required: true, message: 'Campo obrigatório' }, { type: 'email', message: 'E-mail inválido' }]}>
                                    <Input placeholder="E-mail" className="register_form_input" />
                                </Form.Item>
                                <Form.Item name="password_credential" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                                    <Input placeholder="Senha" className="register_form_input" type="password" />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="register_form_button">Cadastrar</Button>
                                    <Link to='/'><Button type="link" block style={{color: 'var(--primary)'}}>Voltar</Button></Link>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                ) : (
                    <Spin />
                )}                
            </Content>
        </Layout>
    )

}

export default withCookies(Screen);