import React from 'react';
import { CookiesProvider } from 'react-cookie';

import AppStack from './routes/AppStack';

// CSS
import './App.css';

const App = () => {
  return (
    <CookiesProvider>
      <AppStack />
    </CookiesProvider>
  )
}

export default App;
