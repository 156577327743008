import React from "react";
import { Drawer } from "antd";
import { withCookies } from "react-cookie";

// COMPONENTS
import FormComponent from "../FormComponent";

class Component extends React.Component {

    render = () => {

        const { _title, _width, _visible, _onVisible, _formulario, _loading, _send, _defaultValues, _delete } = this.props
        
        return (
            <Drawer style={{zIndex: 999}} destroyOnClose title={_title} width={_width} placement="right" onClose={_onVisible} visible={_visible}>
                {_formulario ? (
                    <FormComponent _delete={_delete} _send={_send} _form={_formulario} _cancel={_onVisible} _loading={_loading} _defaultValues={_defaultValues} />
                ) : null}
            </Drawer>
        )
    }
    
}

export default withCookies(Component);