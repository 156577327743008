import React from "react";
import InputMask from 'react-input-mask';
import { Button, Col, Form, Input, Row, Select, Upload } from "antd";
import { withCookies } from "react-cookie";

import './styles.css';

function Component ({_form, _cancel, _loading, _send, _defaultValues, _delete}) {

    const [form] = Form.useForm()
    
    const { Option } = Select;

    return (   
        <Form form={form} layout="vertical" onFinish={_send} initialValues={_defaultValues}>
            <Row gutter={[4,4]}>
                {_form.map((item, index) => (
                    <Col span={item.span} key={index}>
                        <Form.Item className="formitem_class" name={item.name} label={item.label} hidden={(item.render === 'hidden') ? true : false} rules={item.validate}>
                            {item.render === 'hidden' ? (
                                <Input />
                            ) : item.render === 'normal' ? (
                                <Input readOnly={(_send != null) ? false : true} placeholder={item.label} onChange={item.changeValue} label={item.label} type={item.type} />
                            ) : item.render === 'textarea' ? (
                                <Input.TextArea placeholder={item.label} readOnly={(_send != null) ? false : true} rows={4} onChange={item.changeValue} label={item.label} type={item.type} />
                            ) : item.render === 'mask' ? (
                                <InputMask readOnly={(_send != null) ? false : true} mask={item.mask} onBlur={item.changeValue}>
                                    {(inputProps) => <Input placeholder={item.label} {...inputProps} readOnly={(_send != null) ? false : true} label={item.label} type={item.type} />}
                                </InputMask>
                            ) : item.render === 'select' ? (
                                <Select mode={item.mode} allowClear readOnly={(_send != null) ? false : true} placeholder={item.label} onChange={item.changeValue}>
                                    {item.items.map((i, k) => (
                                        <Option disabled={(_send != null) ? false : true} key={k} value={i.value}>{i.label}</Option>
                                    ))}
                                </Select>
                            ) : item.render === 'upload' ? (
                                <Upload listType="picture-card" maxCount={1} accept="image/jpeg,image/png">
                                    Selecionar
                                </Upload>
                            ) : null}
                        </Form.Item>
                    </Col>
                ))}
                {_send != null ? (
                    <Col span={12}>
                        <Form.Item>
                            <Button className="form_buttons primary" block type="primary" htmlType="submit" loading={_loading}>Salvar</Button>
                        </Form.Item>
                    </Col>
                ) : null}
                {_delete != null ? (
                    <Col span={12}>
                        <Form.Item>
                            <Button className="form_buttons danger" onClick={() => {_cancel();_delete()}} block type="primary" loading={_loading}>Deletar</Button>
                        </Form.Item>
                    </Col>
                ) : null}
                <Col span={12}>
                    <Button className="form_buttons default" block onClick={_cancel}>Fechar</Button>
                </Col>
            </Row>
        </Form>
    )

}

export default withCookies(Component);