import React, { useEffect, useState } from "react";
import { Spin, Row, Breadcrumb, Col, Tag, Button, Image, Card, Typography } from "antd";
import { withCookies, useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

// CSS
import './styles.css';

// ICONS
import { MdEdit, MdRemoveRedEye, MdSettings } from 'react-icons/md';

// API'S
import { LOAD_CEP, POST_API, SMART_ALERT } from "../../services/Api";

// COMPONENTS
import DrawerComponent from "../../components/DrawerComponent";


const Screen = ({type}) => {

    // VARIABLES
    const navigate = useNavigate();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    const [dataUser, setDataUser] = useState(null);
    const [dataLojas, setDataLojas] = useState([]);
    const [dataLojasTotal, setDataLojasTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [formloading, setFormLoading] = useState(false);
    const [idState, setIdState] = useState("");
    const [formulario, setFormulario] = useState([]);
    const [defaultValues, setDefaultValues] = useState([]);
    const [fileList, setFileList] = useState([]);

    const [visibleAdd, setVisibleAdd] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleView, setVisibleView] = useState(false);
    
    // COOKIES
    const [cookies] = useCookies(['TOKEN']);

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const loadDatas = async (search) => {
        setLoading(true)
        await POST_API('/addresses/search.php', {token: cookies.TOKEN, search: search }).then(rs => rs.json()).then(res => {
            setDataLojas(res)
            setDataLojasTotal(res.length)
        }).finally(e => {
            setLoading(false)
        })
    }

    const closeAll = () => {
        setVisibleAdd(false)
        setVisibleEdit(false)
        setVisibleView(false)
    }

    const onSendForm = async (values) => {
        setFormLoading(true)
        await POST_API('/addresses/save.php', {token: cookies.TOKEN, ...values, type}, values?.avatar_category?.fileList).then(rs => rs.json()).then(res => {
            SMART_ALERT(res.msg).then(e => {
                if (res.return) {
                    loadDatas('')
                    closeAll()
                }
            })
        }).finally(e => {
            setFormLoading(false)
        })
    }

    const onDelete = async () => {
        SMART_ALERT('Quer mesmo deletar esse item?', 'Atenção!', 'Sim', true, 'Não').then(e => {
            if (e.isConfirmed) {
                POST_API('/addresses/del.php', {token: cookies.TOKEN, ...defaultValues}).then(rs => rs.json()).then(res => {
                    SMART_ALERT(res.msg)
                    closeAll()
                    loadDatas('')
                })
            }
        })
    }

    const renderForm = async (id = "") => {
        var states = [];
        await POST_API('/addresses/states.php', {token: cookies.TOKEN }).then(rs => rs.json()).then(res => {
            res.map(item => {
                states.push({label: item.name_state, value: item.initials_state})
            })
        })
        var cities = [];
        await POST_API('/addresses/cities.php', {token: cookies.TOKEN, id_state: id}).then(rs => rs.json()).then(res => {
            res.map(item => {
                cities.push({label: item.name_city, value: item.ibge_city})
            })
        })
        setFormulario([
            { name: 'id_address', render: 'hidden' },
            { name: 'cep_address', label: 'CEP', type: 'text', validate: [{ required: true, message: 'Campo obrigatório'}], span: 24, render: 'mask', mask: '99999-999' },
            { name: 'initials_state', label: 'Estado', type: 'text', validate: [{ required: true, message: 'Campo obrigatório'}], span: 24, render: 'select', items: states, changeValue: async (e) => {renderForm(e)} },
            { name: 'ibge_city', label: 'Cidade', type: 'text', validate: [{ required: true, message: 'Campo obrigatório'}], span: 24, render: 'select', items: cities },
            { name: 'street_address', label: 'Logradouro', validate: [{ required: true, message: 'Campo obrigatório'}], type: 'text', span: 16, render: 'normal' },
            { name: 'number_address', label: 'Número', type: 'text', span: 8, render: 'normal' },
            { name: 'complement_address', label: 'Complemento', type: 'text', span: 24, render: 'normal' },
            { name: 'district_address', label: 'Bairro', validate: [{ required: true, message: 'Campo obrigatório'}], type: 'text',  span: 24, render: 'normal' },
        ])
    }

    useEffect(() => {
        POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            if (res === 0) navigate('/dashboard')
            setDataUser(res)
            renderForm()
        })
        setScreenLoad(true)
        loadDatas('')
    }, [screenLoad, cookies, navigate]);

    if (screenLoad && dataUser !== null) {
        return (
            <Row gutter={[16,16]} style={{paddingLeft: 10, paddingRight: 10}}>
                <Col span={24} >
                    <Button type="primary" className="add_button_screen" onClick={() => setVisibleAdd(true)}>adicionar endereço</Button>
                </Col>
                {dataLojas && dataLojas.map((item, index) => (
                    <Col xs={24} md={8}>
                        <Card hoverable onClick={() => {setDefaultValues(item); setVisibleEdit(true)}}>
                            <Typography>{item.street_address}, {item.number_address} - {item.district_address}</Typography>
                            <Typography>{item.name_city}/ {item.initials_state} - {item.cep_address} </Typography>
                        </Card>
                    </Col>
                ))}
                <DrawerComponent
                    _title='Adicionar Endereço' 
                    _loading={formloading}
                    _formulario={formulario}
                    _send={onSendForm}
                    _defaultValues={{id_address: 0}}
                    _visible={visibleAdd} _onVisible={closeAll}
                />
                <DrawerComponent
                    _title='Editar Endereço' 
                    _loading={formloading}
                    _formulario={formulario}
                    _send={onSendForm}
                    _delete={onDelete}
                    _defaultValues={defaultValues}
                    _visible={visibleEdit} _onVisible={closeAll}
                />
            </Row>
        )
    } else {
        return (<Row style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}><Spin /></Row>)
    }

}

export default withCookies(Screen);