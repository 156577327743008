import React, { useEffect, useState } from "react";
import { Spin, Row, Col, Tag, Button } from "antd";
import { withCookies, useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

// CSS
import './styles.css';

// ICONS
import { MdEdit, MdRemoveRedEye, MdLocalShipping } from 'react-icons/md';

// API'S
import { POST_API, SMART_ALERT } from "../../../services/Api";

// COMPONENTS
import TableComponent from "../../../components/TableComponent";
import DrawerComponent from "../../../components/DrawerComponent";


const Screen = ({account, user}) => {

    // VARIABLES
    const navigate = useNavigate();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    const [dataUser] = useState(user);
    const [dataLojas, setDataLojas] = useState([]);
    const [dataLojasTotal, setDataLojasTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [formloading, setFormLoading] = useState(false);
    const [formulario, setFormulario] = useState([]);
    const [formularioAddress, setFormularioAddress] = useState([]);
    const [defaultValues, setDefaultValues] = useState([]);

    const [visibleAdd, setVisibleAdd] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleView, setVisibleView] = useState(false);
    const [visibleAddress, setVisibleAddress] = useState(false);
    
    // COOKIES
    const [cookies] = useCookies(['TOKEN']);

    // COLUNAS
    const _columns = [
        { title: 'Cod.', dataIndex: 'id_unity', key: 'id_unity', width: 100, align: 'center' },
        { title: 'Nome', dataIndex: 'name_unity', key: 'name_unity' },
        { title: 'Tipo', dataIndex: 'value_type_unity', key: 'value_type_unity', width: 250 },
        { title: 'Situação', dataIndex: '_status', key: '_status', align: 'center', width: 150, render: (text, _record) => (
            <Tag  key={text} color={_record?._status === 'deletado' ? 'volcano' : _record?._status === 'ativo' ? 'success' : 'warning'}>
                {_record?._status.toUpperCase()}
            </Tag>
        )},
        { title: 'Ações', key: 'action', width: 150, fixed: 'right', align: 'center', render: (text, _record) => (
            <Row gutter={[4,4]} className="table__actions">
                <Col><Button type="link" title="Visualizar" className="table__actions_button_view" onClick={() => {setVisibleView(true); setDefaultValues(_record)}}><MdRemoveRedEye size={15}/></Button></Col>
                <Col><Button type="link" title="Editar" className="table__actions_button_edit" onClick={() => {setVisibleEdit(true); setDefaultValues(_record)}}><MdEdit size={15}/></Button></Col>
                <Col><Button type="link" title="Endereço" className="table__actions_button_config" onClick={() => {setVisibleAddress(true); setDefaultValues(_record)}}><MdLocalShipping size={15}/></Button></Col>
            </Row>
          ),
        },
    ]

    const loadDatas = async (search) => {
        setLoading(true)
        await POST_API('/unities/search.php', {token: cookies.TOKEN, search: search, id_account: account.id_account }).then(rs => rs.json()).then(res => {
            setDataLojas(res)
            setDataLojasTotal(res.length)
        }).finally(e => {
            setLoading(false)
        })
    }

    const closeAll = () => {
        setVisibleAdd(false)
        setVisibleEdit(false)
        setVisibleView(false)
        setVisibleAddress(false)
    }

    const onSend = (values) => {
        loadDatas(values[0].value)
    }

    const onSendForm = async (values) => {
        setFormLoading(true)
        await POST_API('/unities/save.php', {token: cookies.TOKEN, ...values, id_account: account.id_account}).then(rs => rs.json()).then(res => {
            SMART_ALERT(res.msg).then(e => {
                if (res.return) {
                    loadDatas('')
                    closeAll()
                }
            })
        }).finally(e => {
            setFormLoading(false)
        })
    }

    const onSendFormAddress = async (values) => {
        setFormLoading(true)
        await POST_API('/addresses/save.php', {token: cookies.TOKEN, ...values, type: 'U'}).then(rs => rs.json()).then(res => {
            SMART_ALERT(res.msg).then(e => {
                if (res.return) {
                    loadDatas('')
                    closeAll()
                }
            })
        }).finally(e => {
            setFormLoading(false)
        })
    }

    const onDelete = async () => {
        SMART_ALERT('Quer mesmo deletar esse item?', 'Atenção!', 'Sim', true, 'Não').then(e => {
            if (e.isConfirmed) {
                POST_API('/unities/del.php', {token: cookies.TOKEN, ...defaultValues}).then(rs => rs.json()).then(res => {
                    SMART_ALERT(res.msg)
                    closeAll()
                    loadDatas('')
                })
            }
        })
    }

    const renderForm = async (id = "") => {
        var states = [];
        await POST_API('/addresses/states.php', {token: cookies.TOKEN }).then(rs => rs.json()).then(res => {
            res.map(item => {
                states.push({label: item.name_state, value: item.initials_state})
            })
        })
        var cities = [];
        await POST_API('/addresses/cities.php', {token: cookies.TOKEN, id_state: id}).then(rs => rs.json()).then(res => {
            res.map(item => {
                cities.push({label: item.name_city, value: item.ibge_city})
            })
        })
        setFormularioAddress([
            { name: 'id_address', render: 'hidden' },
            { name: 'id_unity', render: 'hidden' },
            { name: 'cep_address', label: 'CEP', type: 'text', validate: [{ required: true, message: 'Campo obrigatório'}], span: 24, render: 'mask', mask: '99999-999' },
            { name: 'initials_state', label: 'Estado', type: 'text', validate: [{ required: true, message: 'Campo obrigatório'}], span: 24, render: 'select', items: states, changeValue: async (e) => {renderForm(e)} },
            { name: 'ibge_city', label: 'Cidade', type: 'text', validate: [{ required: true, message: 'Campo obrigatório'}], span: 24, render: 'select', items: cities },
            { name: 'street_address', label: 'Logradouro', validate: [{ required: true, message: 'Campo obrigatório'}], type: 'text', span: 16, render: 'normal' },
            { name: 'number_address', label: 'Número', type: 'text', span: 8, render: 'normal' },
            { name: 'complement_address', label: 'Complemento', type: 'text', span: 24, render: 'normal' },
            { name: 'district_address', label: 'Bairro', validate: [{ required: true, message: 'Campo obrigatório'}], type: 'text',  span: 24, render: 'normal' },
        ])
    }

    useEffect(() => {
        setScreenLoad(true)
        setFormulario([
            { name: 'id_unity', render: 'hidden' },
            { name: 'name_unity', label: 'Nome', type: 'text', validate: [{ required: true, message: 'Campo obrigatório'}], span: 24, render: 'normal' },
            { name: 'type_unity', label: 'Tipo', type: 'text', validate: [{ required: true, message: 'Campo obrigatório'}], span: 24, render: 'select', items: [
                { label: 'Unidade Principal', value: 'A' },
                { label: 'Unidade Secundária', value: 'B' }
            ] }
        ])
        renderForm()
        loadDatas('')
    }, [screenLoad, cookies, navigate]);

    if (screenLoad && dataUser !== null) {
        return (
            <Row gutter={[4,4]} style={{paddingLeft: 10, paddingRight: 10}}>
                <Col span={24} className="add_button_screen_col">
                    <Button type="primary" className="add_button_screen" onClick={() => setVisibleAdd(true)}>adicionar unidade</Button>
                </Col>
                <TableComponent
                    _title='Lista de Unidades'
                    _columns={_columns}
                    _data={dataLojas}
                    _total_data={dataLojasTotal}
                    _onSend={onSend}
                    _loading={loading}
                />
                <DrawerComponent
                    _title='Adicionar Unidade' 
                    _loading={formloading}
                    _formulario={formulario}
                    _send={onSendForm}
                    _defaultValues={{id_type_unity: '3'}}
                    _visible={visibleAdd} _onVisible={closeAll}
                />
                <DrawerComponent
                    _title='Visualizar Unidade' 
                    _loading={formloading}
                    _formulario={formulario}
                    _defaultValues={defaultValues}
                    _delete={onDelete}
                    _visible={visibleView} _onVisible={closeAll}
                />
                <DrawerComponent
                    _title='Editar Unidade' 
                    _loading={formloading}
                    _formulario={formulario}
                    _send={onSendForm}
                    _defaultValues={defaultValues}
                    _visible={visibleEdit} _onVisible={closeAll}
                />
                <DrawerComponent
                    _title='Atualizar endereço da unidade' 
                    _loading={formloading}
                    _formulario={formularioAddress}
                    _send={onSendFormAddress}
                    _defaultValues={defaultValues}
                    _visible={visibleAddress} _onVisible={closeAll}
                />
            </Row>
        )
    } else {
        return (<Row style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}><Spin /></Row>)
    }

}

export default withCookies(Screen);