import React, { useEffect, useState } from "react";
import { Spin, Row } from "antd";
import { withCookies, useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

// API'S
import { POST_API } from "../../services/Api";
import CaregoriaScreen from "./CategoriasScreen";
import AdminScreen from "./AdminScreen";

const Screen = () => {

    // VARIABLES
    const navigate = useNavigate();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    const [dataUser, setDataUser] = useState(null);
    
    // COOKIES
    const [cookies] = useCookies(['TOKEN']);

    useEffect(() => {
        POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            if (res === 0) navigate('/dashboard')
            setDataUser(res)
        })
        setScreenLoad(true)
    }, [screenLoad, cookies, navigate]);

    if (screenLoad && dataUser !== null) {
        if (dataUser.id_type_credential === '/MkqpeTycaFmbALP/PE2/A==' || dataUser.id_type_credential === 'v8SLZzQX6XF8T8m04mHdYg==') {
            return <CaregoriaScreen />
        }
        if (dataUser.id_type_credential === 'L+RrmCs/TTfi8KP6hqp0pg==') {
            return <AdminScreen />
        }
    } else {
        return (<Row style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}><Spin /></Row>)
    }

}

export default withCookies(Screen);