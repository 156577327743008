import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Image, Form, Input, Button, Spin} from "antd";
import { withCookies, useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

// CSS
import './styles.css';

// API'S
import { POST_API, SMART_ALERT, ACCOUNT_LOGO, ACCOUNT_ID } from "../../services/Api";

const Screen = () => {

    // VARIABLES
    const { Content } = Layout;
    const navigate = useNavigate();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    
    // COOKIES
    const [cookies, setCookies] = useCookies(['LOGIN', 'TOKEN']);

    // FORMS
    const [login_form] = Form.useForm();

    useEffect(() => {
        if (cookies.TOKEN !== '') {
            POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
                if (Number(res.id_account) === ACCOUNT_ID) {
                    navigate('dashboard')
                }
            })
        }
        setScreenLoad(true)
    }, [screenLoad, cookies, navigate]);

    const onSend = async (values) => {
        await POST_API('/credentials/login.php', {...values, id_account: ACCOUNT_ID}).then(rs => rs.json()).then(res => {
            SMART_ALERT(res.msg)
            if (res.return) {
                var data = new Date()
                data.setDate(data.getDate() + 7);
                setCookies('TOKEN', res.token, { path: '/', expires: data })
                navigate('dashboard')
            }
        })
    }

    return (
        <Layout>
            <Content className="layout_login">
                {screenLoad ? (
                    <Row className="layout_login_row">
                        <Col span={24} style={{display: 'flex', justifyContent: 'center', marginBottom: 10}}>
                            <Image src={ACCOUNT_LOGO} width={'70%'} preview={false} />
                        </Col>
                        <Col span={24}>
                            <Form name="login_form" onFinish={onSend} form={login_form}>
                                <Form.Item name="login" rules={[{ required: true, message: 'Campo obrigatório' }, { type: 'email', message: 'E-mail inválido' }]}>
                                    <Input placeholder="E-mail" className="login_form_input" />
                                </Form.Item>
                                <Form.Item name="senha" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                                    <Input placeholder="Senha" className="login_form_input" type="password" />
                                </Form.Item>
                                <Form.Item>
                                    <Link to='esquecisenha' className="login_form_forgot">Esqueceu a senha?</Link>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login_form_button">Fazer login</Button>
                                    {ACCOUNT_ID === 1 ? null : <Link to='cadastrar'><Button type="link" block style={{color: 'var(--primary)'}}>Cadastre-se</Button></Link>}
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                ) : (
                    <Spin />
                )}                
            </Content>
        </Layout>
    )

}

export default withCookies(Screen);