import React, { useEffect, useState } from "react";
import { Spin, Row, Breadcrumb, Col, Tag, Button, Typography, Image, Form, Input, Upload, Select, InputNumber } from "antd";
import { withCookies, useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import ImgCrop from 'antd-img-crop';

// CSS
import './styles.css';

// ICONS
import { MdEdit, MdRemoveRedEye, MdSettings } from 'react-icons/md';

// API'S
import { ACCOUNT_LOGO, POST_API, SMART_ALERT } from "../../../services/Api";

// COMPONENTS
import TableComponent from "../../../components/TableComponent";
import DrawerComponent from "../../../components/DrawerComponent";


const Screen = ({account, user}) => {

    // VARIABLES
    const navigate = useNavigate();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [configAccount, setConfigAccount] = useState(null);
    const [dataPlanos, setDataPlanos] = useState(null);
    const [dataUser] = useState(user)
    
    // COOKIES
    const [cookies] = useCookies(['TOKEN']);

    const [form] = Form.useForm()

    const onSend = (values) => {
        setLoadingForm(true)
        POST_API('/accounts/save_config.php', {token: cookies.TOKEN, ...values, id_account: account.id_account}, fileList).then(rs => rs.json()).then(res => {
            SMART_ALERT(res.msg)
            if (res.return) {
                POST_API('/accounts/config.php', {token: cookies.TOKEN, id_account: account.id_account}).then(rs => rs.json()).then(res => {
                    setConfigAccount(res)
                })
            }
        }).finally(() => {
            setLoadingForm(false)
        })
    }

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    useEffect(() => {
        setScreenLoad(true)
        POST_API('/plans/search.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            setDataPlanos(res)
        })
        POST_API('/accounts/config.php', {token: cookies.TOKEN, id_account: account.id_account}).then(rs => rs.json()).then(res => {
            setConfigAccount(res)
        })
    }, [screenLoad, cookies, navigate]);

    if (screenLoad && dataUser !== null && configAccount !== null && dataPlanos !== null) {
        form.setFieldsValue(configAccount)
        return (
            <Row gutter={[4,4]} style={{paddingLeft: 10, paddingRight: 10}}>
                <Col span={20}>
                    <Row gutter={[16,16]}>
                        <Col span={8}>
                            <Image style={{width: '100%'}} src={configAccount.logo_empresa} preview={false} />
                        </Col>
                        <Col span={8}>
                            <Form layout="vertical" onFinish={onSend} form={form} defaultValue={configAccount}>
                                <Form.Item className="formitem_class" name="logo_empresa" label="Nova logo">
                                    <Upload onChange={onChange} listType="picture-card" maxCount={1} accept="image/jpeg,image/png">
                                        Selecionar
                                    </Upload>
                                </Form.Item>
                                <Form.Item className="formitem_class" name="cor_primaria" label="Cor Destaque">
                                    <Input type="color"/>
                                </Form.Item>
                                <Form.Item className="formitem_class" name="taxa_empresa" label="Taxa de Entrega Padrão">
                                    <InputNumber min={0} />
                                </Form.Item>
                                <Form.Item className="formitem_class" name="plano_empresa" label="Plano Contratado">
                                    <Select allowClear>
                                        {dataPlanos.map((i, k) => (
                                            <Select.Option key={k} value={i.id_plan}>{i.name_plan}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    <Button className="form_buttons primary" block type="primary" htmlType="submit" loading={loadingForm}>Salvar</Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    } else {
        return (<Row style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}><Spin /></Row>)
    }

}

export default withCookies(Screen);