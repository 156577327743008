import React from "react";
import { Col, Form, Input, Row, Table, Typography } from "antd";
import { withCookies } from "react-cookie";

// CSS
import './styles.css';

class Component extends React.Component {

    render = () => {

        const { _title, _columns, _data, _total_data, _onChangeTable, _onSend, _loading } = this.props

        return (
            <Row className="table__row_table">
                <Col span={12}>
                    <Typography className="table__title">{_title}</Typography>
                    <Typography className="table__summary">Total de {_total_data} registros retornados</Typography>
                </Col>
                <Col span={12}>
                    <Form layout="vertical" onFieldsChange={_onSend} className="table__form_filter">
                        <Form.Item name="search">
                            <Input onChange={null} placeholder="Perquisar..." variant="standard" className="table__form_filter_input" />
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={24} style={{marginTop: 10}}>
                    <Table
                        onChange={_onChangeTable}
                        columns={_columns}
                        dataSource={_data} 
                        loading={_loading}
                        scroll={{x: true}}
                        className='table__'
                    />
                </Col>
            </Row>
        )
    }
    
}

export default withCookies(Component);