import React, { useEffect, useState } from "react";
import { Spin, Row, Breadcrumb, Col, Button, Drawer, Typography, Divider, Image, Select } from "antd";
import { withCookies, useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

// CSS
import './styles.css';

// ICONS
import { MdRemoveRedEye, MdOutlineLocalPrintshop } from 'react-icons/md';

// API'S
import { ACCOUNT_TAX, number_format, POST_API, SMART_ALERT, URL_API } from "../../../services/Api";

// COMPONENTS
import TableComponent from "../../../components/TableComponent";

const Screen = () => {

    // VARIABLES
    const navigate = useNavigate();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    const [dataUser, setDataUser] = useState(null);
    const [dataLojas, setDataLojas] = useState([]);
    const [dataLojasTotal, setDataLojasTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [formloading, setFormLoading] = useState(false);
    const [formulario, setFormulario] = useState([]);
    const [defaultValues, setDefaultValues] = useState([]);
    const [valorTotal, setValorTotal] = useState(0);
    const [dataCart, setDataCart] = useState([]);
    const [dataCartProducts, setDataCartProducts] = useState([]);
    const [valorStatus, setValorStatus] = useState(null);

    const [visibleAdd, setVisibleAdd] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleView, setVisibleView] = useState(false);
    
    // COOKIES
    const [cookies] = useCookies(['TOKEN']);

    const printOrder = async (id) => {
        console.log(cookies.TOKEN)
        var mapForm = document.createElement("form");
        mapForm.target = "_blank";    
        mapForm.method = "POST";
        mapForm.action = `${URL_API}/nota/gerar.php`;

        // Create an input
        var mapInput = document.createElement("input");
        mapInput.type = "text";
        mapInput.name = "token";
        mapInput.value = cookies.TOKEN;

        var mapInputId = document.createElement("input");
        mapInputId.type = "text";
        mapInputId.name = "id_order";
        mapInputId.value = id;

        // Add the input to the form
        mapForm.appendChild(mapInput);
        mapForm.appendChild(mapInputId);

        // Add the form to dom
        document.body.appendChild(mapForm);

        // Just submit
        mapForm.submit();
        // var string = String(cookies.TOKEN).replace(' ', '%2B%2F')
        // window.open(`${URL_API}/nota/gerar.php?token=${string}&id_order=${id}`);
    }

    // COLUNAS
    const _columns = [
        { title: 'Cod.', dataIndex: 'id_order', key: 'id_order', align: 'center' },
        { title: 'Cliente', dataIndex: 'name_credential', key: 'name_credential', align: 'center' },
        { title: 'Status', dataIndex: 'value_status_order', key: 'value_status_order', align: 'center' },
        { title: 'Data/hora', dataIndex: 'format_datetime_insert', key: 'format_datetime_insert', align: 'center' },
        { title: 'Ações', key: 'action', width: 150, fixed: 'right', align: 'center', render: (text, _record) => (
            <Row gutter={[4,4]} className="table__actions">
                <Col><Button type="link" title="Visualizar" className="table__actions_button_view" onClick={() => {setVisibleView(true); loadCart(_record.id_cart); setDataCart(_record)}}><MdRemoveRedEye size={15}/></Button></Col>
                <Col><Button type="link" title="Imprimir nota" className="table__actions_button_edit" onClick={(e) => printOrder(_record.id_order)}><MdOutlineLocalPrintshop size={15}/></Button></Col>
            </Row>
          ),
        },
    ]

    const loadCart = async (id) => {
        await POST_API('/carts/list_product.php', {token: cookies.TOKEN, id_cart: id}).then(rs => rs.json()).then(res => {
            setDataCartProducts(res)
            var total = 0
            res.map(async item => {
                total += item.total_value_price_product
            })
            setValorTotal(total)
        })
    }

    const loadDatas = async (search) => {
        setLoading(true)
        await POST_API('/orders/search.php', {token: cookies.TOKEN, search: search, id_credential: ''}).then(rs => rs.json()).then(res => {
            setDataLojas(res)
            setDataLojasTotal(res.length)
        }).finally(e => {
            setLoading(false)
        })
    }

    const closeAll = () => {
        setVisibleAdd(false)
        setVisibleEdit(false)
        setVisibleView(false)
    }

    const onSend = (values) => {
        loadDatas(values[0].value)
    }

    const sendStatus = async () => {
        await POST_API('/orders/save.php', {token: cookies.TOKEN, status: valorStatus, ...dataCart}).then(rs => rs.json()).then(res => {
            SMART_ALERT(res.msg).then(e => {
                if (res.return) {
                    loadDatas('')
                    closeAll()
                }
            })
        }).finally(e => {
            setFormLoading(false)
        })
    }

    useEffect(() => {
        POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            if (res === 0) navigate('/dashboard')
            if (res.id_type_credential === '/MkqpeTycaFmbALP/PE2/A==') navigate('/dashboard')
            setDataUser(res)
        })
        setScreenLoad(true)
        loadDatas('')
    }, [screenLoad, cookies, navigate]);

    if (screenLoad && dataUser !== null) {
        return (
            <Row gutter={[16,16]} style={{paddingLeft: 10, paddingRight: 10}}>
                <Col span={12}>
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/dashboard">Dashboard</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Pedidos</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <TableComponent
                    _title='Lista de Pedidos'
                    _columns={_columns}
                    _data={dataLojas}
                    _total_data={dataLojasTotal}
                    _onSend={onSend}
                    _loading={loading}
                />
                <Drawer visible={visibleView} onClose={closeAll}>
                <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Typography style={{color: 'var(--primary)', fontSize: 18, fontWeight: '700', marginTop: 30}}>Pedido</Typography>
                        </Col>
                        {dataCartProducts.map((item, index) => (
                            <Col span={24} key={index}>
                                <Row style={{alignItems: 'center'}}>
                                    <Image style={{width: 50,height: 50, objectFit: 'cover', borderRadius: 8, marginRight: 8}} preview={false} src={item.avatar_product} />
                                    <Typography style={{width: 30}}>X {item.qtd_cart_product}</Typography>
                                    <div style={{marginRight: 8}}>
                                        <Typography style={{fontWeight: '700'}}>{item.name_product}</Typography>
                                        <Typography style={{fontSize: 16, color: 'var(--primary)'}}>{item.total_price_product}</Typography>
                                    </div>
                                </Row>
                                <Row>
                                    <Col span={24}>{item.obs_cart_product}</Col>
                                </Row>
                            </Col>
                        ))}
                        <Col span={24}>
                        {dataCart?.id_address > 0 ? (
                            <>
                            <Row justify="space-between" style={{marginTop: 20}}>
                                <Typography style={{fontSize: 18}}>Taxa de entrega:</Typography>
                                <Typography style={{fontSize: 18, color: 'var(--primary)'}}>{number_format(ACCOUNT_TAX)}</Typography>
                            </Row>
                            <Divider/>
                            <Row justify="space-between">
                                <Typography style={{fontSize: 18}}>Total</Typography>
                                <Typography style={{fontSize: 18, color: 'var(--primary)'}}>{number_format(valorTotal+ACCOUNT_TAX)}</Typography>
                            </Row>
                            </>
                        ) : (
                            <Row justify="space-between">
                                <Typography style={{fontSize: 18}}>Total</Typography>
                                <Typography style={{fontSize: 18, color: 'var(--primary)'}}>{number_format(valorTotal)}</Typography>
                            </Row>
                        )}
                            <Row justify="space-between" style={{marginTop: 10}}>
                                <Col span={24}><Typography style={{fontSize: 18}}>Para</Typography></Col>
                                <Col span={24}><Typography style={{fontSize: 18, color: 'var(--primary)'}}>{dataCart?.name_credential}</Typography></Col>
                            </Row>
                            {dataCart?.id_address > 0 ? (
                                <Row justify="space-between" style={{marginTop: 10}}>
                                    <Col span={24}><Typography style={{fontSize: 18}}>Endereço de entrega</Typography></Col>
                                    <Col span={24}><Typography style={{fontSize: 18, color: 'var(--primary)'}}>{dataCart?.street_address}, {dataCart?.number_address} - {dataCart?.district_address} - {dataCart?.name_city} / {dataCart?.initials_state} - {dataCart?.cep_address} </Typography></Col>
                                </Row>
                            ) : (
                                <Row justify="space-between" style={{marginTop: 10}}>
                                    <Col span={24}><Typography style={{fontSize: 18, color: 'var(--primary)'}}>Retirar no local</Typography></Col>
                                </Row>
                            )}
                            <Row justify="space-between" style={{marginTop: 10}}>
                                <Col span={24}><Typography style={{fontSize: 18}}>Forma de pagamento</Typography></Col>
                                <Col span={24}><Typography style={{fontSize: 18, color: 'var(--primary)'}}>{dataCart?.value_method_order}</Typography></Col>
                            </Row>
                            {/* <Button type="primary" onClick={finalized} block style={{backgroundColor: 'var(--primary)', border: 'none', marginTop: 20, borderRadius: 8, height: 40}}>Finalizar pedido</Button> */}
                        </Col>
                        <Col span={16}>
                            <Select value={valorStatus} onChange={(e) => setValorStatus(e)} placeholder="Atualizar status do pedido" style={{width: '100%'}}>
                                <Select.Option value="P">Em Processo</Select.Option>
                                <Select.Option value="A">A Caminho</Select.Option>
                                <Select.Option value="E">Entregue</Select.Option>
                                <Select.Option value="C">Cancelado</Select.Option>
                            </Select>
                        </Col>
                        <Col span={8}>
                            <Button onClick={sendStatus} block type="primary" style={{backgroundColor: 'var(--primary)', border: 'none'}}>OK</Button>
                        </Col>
                    </Row>
                </Drawer>
            </Row>
        )
    } else {
        return (<Row style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}><Spin /></Row>)
    }

}

export default withCookies(Screen);