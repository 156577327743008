import React from "react";
import { Layout, Typography, Row, Button } from "antd";
import { withCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

// CSS
import './styles.css';

const Screen = () => {

    // VARIABLES
    const { Content } = Layout;
    const { Title } = Typography;
    const navigate = useNavigate();

    return (
        <Layout>
            <Content className="layout_page404">
                <Row className="layout_page404_row">
                    <Title className="layout_page404_row_title">404</Title>
                    <Title className="layout_page404_row_subtitle">Opps! Página não encontrada</Title>
                    <Title className="layout_page404_row_text">Desculpe, a página que você está procurando não existe!</Title>
                    <Button onClick={() => navigate('/dashboard')} className="layout_page404_row_button">Retornar</Button>
                </Row>    
            </Content>
        </Layout>
    )

}

export default withCookies(Screen);