import React from "react";
import { Row, Col, Button} from "antd";
import { withCookies, useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

// CSS
import './styles.css';

// ICONS
import { MdOutlineWidgets, MdOutlineLocalMall, MdOutlineGroups, MdOutlineScreenSearchDesktop, MdOutlineSettings, MdOutlineAssignment, MdOutlinePerson, MdOutlineLogout, MdOutlineFastfood, MdOutlineDining } from 'react-icons/md';

// API'S
import { ACCOUNT_LOGO, SMART_ALERT } from "../../services/Api";

const Screen = ({_user}) => {

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['TOKEN']);

    const onLogout = () => {
        SMART_ALERT('Quer sair?', 'Tem certeza?', 'Sim', true, 'Não').then(rs => {
            if (rs.isConfirmed) {
                navigate('/')
                removeCookie('TOKEN', {path: '/'})
            }
        })
    }

    return (
        <Row gutter={[16,16]}>
            {/* LOGO */}
            <Col span={24} className="sidebar_col">
                <img style={{width: '100%'}} src={ACCOUNT_LOGO} alt=""/>
            </Col>

            {/* DASHBOARD */}
            <Col span={24} className="sidebar_col">
                <Link to="/dashboard" title="Dashboard"><Button type="primary" className="sidebar_button"><MdOutlineWidgets size={22} /></Button></Link>
            </Col>

            {/* PERFIL */}
            <Col span={24} className="sidebar_col">
                <Link to="perfil" title="Perfil"><Button type="primary" className="sidebar_button"><MdOutlinePerson size={22} /></Button></Link>
            </Col>

            {/* MEUS PEDIDOS */}
            {_user.id_type_credential === "/MkqpeTycaFmbALP/PE2/A==" ? (
                <Col span={24} className="sidebar_col">
                    <Link to="meuspedidos" title="Meus Pedidos"><Button type="primary" className="sidebar_button"><MdOutlineLocalMall size={22} /></Button></Link>
                </Col>
            ):null}

            {/* PEDIDOS */}
            {_user.id_type_credential !== "/MkqpeTycaFmbALP/PE2/A==" && _user.id_type_credential !== "cqGERue0NkPXaiMkaPILuQ==" ? (
                <Col span={24} className="sidebar_col">
                    <Link to="pedidos" title="Pedidos"><Button type="primary" className="sidebar_button"><MdOutlineLocalMall size={22} /></Button></Link>
                </Col>
            ):null}

            {/* LOJAS - PLATAFORMA */}
            {_user.id_type_credential === 'cqGERue0NkPXaiMkaPILuQ==' ? (
                <Col span={24} className="sidebar_col">
                    <Link to="lojas" title="Lojas"><Button type="primary" className="sidebar_button"><MdOutlineLocalMall size={22} /></Button></Link>
                </Col>
            ):null}

            {/* PLANOS - PLATAFORMA */}
            {_user.id_type_credential === 'cqGERue0NkPXaiMkaPILuQ==' ? (
            <Col span={24} className="sidebar_col">
                <Link to="planos" title="Planos"><Button type="primary" className="sidebar_button"><MdOutlineScreenSearchDesktop size={22} /></Button></Link>
            </Col>
            ):null}

            {/* CATEGORIAS */}
            {_user.id_type_credential === 'L+RrmCs/TTfi8KP6hqp0pg==' || _user.id_type_credential === 'fG12miB0mgIv46xaa/qqlw==' ? (
            <Col span={24} className="sidebar_col">
                <Link to="categorias" title="Categorias"><Button type="primary" className="sidebar_button"><MdOutlineDining size={22} /></Button></Link>
            </Col>
            ):null}

            {/* PRODUTOS */}
            {_user.id_type_credential === 'L+RrmCs/TTfi8KP6hqp0pg==' || _user.id_type_credential === 'fG12miB0mgIv46xaa/qqlw==' ? (
            <Col span={24} className="sidebar_col">
                <Link to="produtos" title="Produtos"><Button type="primary" className="sidebar_button"><MdOutlineFastfood size={22} /></Button></Link>
            </Col>
            ):null}

            {/* USUÁRIOS */}
            {_user.id_type_credential === 'L+RrmCs/TTfi8KP6hqp0pg==' || _user.id_type_credential === 'fG12miB0mgIv46xaa/qqlw==' ? (
            <Col span={24} className="sidebar_col">
                <Link to="usuarios" title="Usuários"><Button type="primary" className="sidebar_button"><MdOutlineGroups size={22} /></Button></Link>
            </Col>
            ):null}

            {/* LOGS - PLATAFORMA */}
            {_user.id_type_credential === 'cqGERue0NkPXaiMkaPILuQ==' ? (
            <Col span={24} className="sidebar_col">
                <Link to="logs" title="Logs do Sistema"><Button type="primary" className="sidebar_button"><MdOutlineAssignment size={22} /></Button></Link>
            </Col>
            ):null}

            {/* CONFIGURAÇÕES */}
            {_user.id_type_credential === 'L+RrmCs/TTfi8KP6hqp0pg==' || _user.id_type_credential === 'cqGERue0NkPXaiMkaPILuQ==' ? (
            <Col span={24} className="sidebar_col">
                <Link to="configuracoes" title="Configurações"><Button type="primary" className="sidebar_button"><MdOutlineSettings size={22} /></Button></Link>
            </Col>
            ):null}

            {/* SAIR */}
            <Col span={24} className="sidebar_col">
                <Button type="primary" className="sidebar_button" onClick={onLogout}><MdOutlineLogout size={22} /></Button>
            </Col>
        </Row>
    )

}

export default withCookies(Screen);