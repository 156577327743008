import React, { useEffect, useState } from "react";
import { Spin, Row, Image, Card, Col } from "antd";
import { withCookies, useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

// CSS
import './styles.css';

// API'S
import { POST_API, SMART_ALERT } from "../../../services/Api";

const Screen = () => {

    // VARIABLES
    const navigate = useNavigate();

    // STATES
    const [screenLoad, setScreenLoad] = useState(false);
    const [dataUser, setDataUser] = useState(null);
    const [dataCategoria, setDataCategoria] = useState(null);
    
    // COOKIES
    const [cookies] = useCookies(['TOKEN']);

    useEffect(() => {
        POST_API('/credentials/load.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            if (res === 0) navigate('/dashboard')
            setDataUser(res)
        })
        POST_API('/categories/search.php', {token: cookies.TOKEN}).then(rs => rs.json()).then(res => {
            setDataCategoria(res)
            console.log(res)
        })
        setScreenLoad(true)
    }, [screenLoad, cookies, navigate]);

    if (screenLoad && dataUser !== null) {
        return (
            <Row gutter={[16,16]} style={{paddingLeft: 10, paddingRight: 10}}>
                {dataCategoria && dataCategoria.map((item, index) => {
                    return (
                        <Col xs={24} md={12} className="category_item" index={index}>
                            <Link to={'categorias/'+item.key}>
                                <Card
                                    hoverable
                                    style={{ width: '100%', borderRadius: 15 }}
                                >
                                    <Image className="image_category" style={{ width: '200px', height: '200px', objectFit: 'cover', borderRadius: 15, }} src={item.avatar_category} preview={false} />
                                    <Card.Meta title={item.name_category} description={item.desc_category} />
                                </Card>
                            </Link>
                        </Col>
                    )
                })}
            </Row>
        )
    } else {
        return (<Row style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}><Spin /></Row>)
    }

}

export default withCookies(Screen);